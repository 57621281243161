<!--
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-19 14:00:54
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-02-26 16:44:41
 -->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
  },
};
</script>
<style >
/* 设置滚动条的样式 */

/* ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
} */
/* 滚动槽 */
/* ::-webkit-scrollbar-track {
  border-radius: 5px;
} */
/* 滚动条滑块 */
/* ::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #c3c3c3;
} */
/* ::-webkit-scrollbar-thumb:window-inactive {
  background: #c3c3c3;
} */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  border-radius: 5px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #e5e5e5;
  opacity: 0;
  cursor: pointer;
}
::-webkit-scrollbar-thumb:hover {
  background: #c3c3c3;
}
::-webkit-scrollbar-thumb:window-inactive {
  border-radius: 5px;
  background: #c3c3c3;
}
</style>
