/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-28 15:37:26
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-02-28 15:40:48
 */

import Router from 'vue-router';
import groupRouter from './grouprouter';
import hotelRouter from './hotelrouter';
import CustomRouterView from './router-view.vue';

const routes = [
  {
    path: '/settlement-order',
    component: CustomRouterView,
    children: [
      ...groupRouter,
      ...hotelRouter,
    ],
  },
];

export default new Router({
  mode: 'history',
  routes,
});
