import apiMapping from './apilist';
import downloadImg from './downloadimg';
import urlUtil from '../common/urlUtil';

const {
  showToast, fetch, download, urlToDownload,
} = window.$axios;

function handleUrlFetch(data) {
  return fetch({
    ...data,
    url: apiMapping[data.url],
  });
}

function getUrlInNoFetch(url) {
  return urlUtil.handleUrlHost(apiMapping[url]);
}

function getBaseUrlInNoFetch(url) {
  return apiMapping[url];
}

export default {
  showToast,
  fetch: handleUrlFetch,
  download,
  urlToDownload,
  getUrlInNoFetch,
  downloadImg,
  getBaseUrlInNoFetch,
};
