/*
 * @Description: 
 * @Author: wangxiaole
 * @Date: 2021-05-13 14:48:49
 * @LastEditTime: 2022-08-23 15:29:11
 * @LastEditors:  
 */
import startsWith from 'lodash/startsWith';
import isEmpty from 'lodash/isEmpty';

/**
 * 根据路径匹配出对应的域名
 * @param path 接口路径
 * @returns {string} 请求的绝对路径
 */
function handleUrlHost(path = '') {
  if (!window.domainMap || isEmpty(window.domainMap)) {
    console.error('未配置 domainMap 域名');
  }
  let uri;
  let key;
  let url;
  const matchKeys = Object.keys(window.domainMap);
  for (let i = 0; i < matchKeys.length; i += 1) {
    key = matchKeys[i];
    if (startsWith(path, key)) {
      uri = window.domainMap[key];
      break;
    }
  }
  url = uri + path;
  if (window.$cookies.get('ssoid')) {
    const ssoid = window.$cookies.get('ssoid');
    url = `${url}?ssoid=${ssoid}`;
  }
  return url;
}

export default {
  handleUrlHost,
};
