// 酒店端路由-结算中心
const settlementHotelRouter = [
  {
    // 账单管理-未入账账单-酒店
    path: 'noAccountBill',
    name: 'noAccountBill',
    meta: {
      permissionCode: 'cp-settlementCenter',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/financial-setting/no-account-bill.vue'),
    // component: () => import('../views/hotel-management/settlement-center/direct-sell-bill-new/index.vue'),
  },
  {
    // 账单管理-未入账账单-客房订单详情
    path: 'noAccountBill/roomOrderDetail',
    name: 'noAccountBillRoomOrderDetail',
    meta: {
      permissionCode: 'cp-settlementCenter',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/order-list/room-order-detail.vue'),
  },
  {
    // 账单管理-已入账账单
    path: 'accountBill',
    name: 'accountBill',
    meta: {
      permissionCode: 'cp-settlementCenter',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/financial-setting/account-bill.vue'),
    // component: () => import('../views/hotel-management/settlement-center/direct-sell-bill-new/account-bill.vue'),
    children: [
      {
        // 结算中心-直销账单-已入账账单 现付
        path: 'accountCashPaymentBill',
        name: 'accountCashPaymentBill',
        meta: {
          permissionCode: 'cp-settlementCenter',
          routerType: 3,
        },
        // component: () => import('../views/hotel-management/financial-setting/account-bill.vue'),
        component: () => import('../views/hotel-management/settlement-center/direct-sell-bill-new/account-cash-payment-bill/index.vue'),
      },
      {
        // 结算中心-直销账单-已入账账单 预付
        path: 'accountPerPaymentBill',
        name: 'accountPerPaymentBill',
        meta: {
          permissionCode: 'cp-settlementCenter',
          routerType: 3,
        },
        // component: () => import('../views/hotel-management/financial-setting/account-bill.vue'),
        component: () => import('../views/hotel-management/settlement-center/direct-sell-bill-new/account-perpayment-bill/index.vue'),
      },
      {
        // 结算中心-直销账单-已入账账单 预付-账单列表
        path: 'accountPerPaymentBillList',
        name: 'accountPerPaymentBillList',
        meta: {
          permissionCode: 'cp-settlementCenter',
          routerType: 3,
        },
        // component: () => import('../views/hotel-management/financial-setting/account-bill.vue'),
        component: () => import('../views/hotel-management/settlement-center/direct-sell-bill-new/account-perpayment-bill/bill-list/index.vue'),
      },
    ],
  },
  {
    // 账单管理-已入账账单-详情
    path: 'accountBillDetail',
    name: 'accountBillDetail',
    meta: {
      permissionCode: 'cp-settlementCenter',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/financial-setting/account-bill-detail.vue'),
  },
  {
    // 账单管理-已入账账单-客房订单详情
    path: 'accountBillDetail/roomOrderDetail',
    name: 'accountBillDetailRoomOrderDetail',
    meta: {
      permissionCode: 'cp-settlementCenter',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/order-list/room-order-detail.vue'),
  },
  {
    // 账单管理-收款账号
    path: 'gatherAccount',
    name: 'gatherAccount',
    meta: {
      permissionCode: 'cp-settlementCenter',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/financial-setting/gather-account.vue'),
  },
  {
    // 余额提现
    path: 'withDraw',
    name: 'withDraw',
    meta: {
      permissionCode: 'cp-hotel-lcSettlementCenter',
      routerType: 3,
      h_authCode: '2',
    },
    component: () => import('../views/hotel-management/financial-setting/with-draw.vue'),
    // component: () => import('../views/hotel-management/settlement-center/balance-withdrawal/index.vue'),
  },
  // {
  //   // 余额提现-提现记录
  //   path: 'withdrawalRecord',
  //   name: 'withdrawalRecord',
  //   meta: {
  //     permissionCode: 'cp-hotel-lcSettlementCenter',
  //     routerType: 3,
  //     h_authCode: '2',
  //   },
  //   component: () => import('../views/hotel-management/settlement-center/balance-withdrawal/withdrawal-record/index.vue'),
  // },
  // {
  //   // 余额提现-收支情况
  //   path: 'incomeExpenditure',
  //   name: 'incomeExpenditure',
  //   meta: {
  //     permissionCode: 'cp-hotel-lcSettlementCenter',
  //     routerType: 3,
  //     h_authCode: '2',
  //   },
  //   component: () => import('../views/hotel-management/settlement-center/balance-withdrawal/income-expenditure/index.vue'),
  // },
  {
    // 预售券分账金额详情
    path: 'accountBill/ticketSplitMoneyDetail/:startDate/:endDate',
    name: 'ticketSplitMoneyDetail',
    meta: {
      permissionCode: 'cp-settlementCenter',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/financial-setting/common-ticket-split-monen.vue'),
  },
  {
    // 奖励分成详情
    path: 'accountBill/rewardDividedDetail/:startDate/:endDate',
    name: 'rewardDividedDetail',
    meta: {
      permissionCode: 'cp-settlementCenter',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/financial-setting/reward-divided.vue'),
  },
  {
    // 结算中心，对账单审核
    path: 'hotelStatementReview',
    name: 'hotelStatementReview',
    meta: {
      permissionCode: 'cp-statementReview',
      routerType: 3,
    },
    component: () => import('../views/group-management/settlement-center/statement-review/index.vue'),
  },
  {
    // 结算中心，对账单审核明细
    path: 'hotelStatementReview/detail/:hotelId/:statementId/:checkStatus/:createDate/:endDate',
    name: 'hotelStatementReviewDetail',
    meta: {
      permissionCode: 'cp-statementReview',
      routerType: 3,
    },
    component: () => import('../views/group-management/settlement-center/statement-review/detail/index.vue'),
  },
  {
    // 结算中心，分销对账单审核
    path: 'hotelDistributionStatementReview',
    name: 'hotelDistributionStatementReview',
    meta: {
      permissionCode: 'cp-statementReview',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/settlement-center/distribution-statement-review/index.vue'),
  },
  {
    // 结算中心，分销对账单审核明细
    path: 'hotelDistributionStatementReviewDetail',
    name: 'hotelDistributionStatementReviewDetail',
    meta: {
      permissionCode: 'cp-statementReview',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/settlement-center/distribution-statement-review/bill-detail/index.vue'),
  },
  {
    // 结算中心，分销对账单发送审核结果
    path: 'hotelDistributionSendVerifyResult',
    name: 'hotelDistributionSendVerifyResult',
    meta: {
      permissionCode: 'cp-statementReview',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/settlement-center/distribution-statement-review/send-verify-result/index.vue'),
  },
  {
    // 结算中心，分销对账单审核结果明细
    path: 'hotelDistributionVerifyResultDetail',
    name: 'hotelDistributionVerifyResultDetail',
    meta: {
      permissionCode: 'cp-statementReview',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/settlement-center/distribution-statement-review/verify-result-detail/index.vue'),
  },
  {
    // 结算中心，分销账单
    path: 'distributionBill',
    name: 'distributionBill',
    meta: {
      permissionCode: 'cp-hotelDistributionBill',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/settlement-center/distribution-bill/index.vue'),
  },
  {
    // 结算中心，分销账单详情
    path: 'distributionBillInfo/:statementDate/:statementId/:batchNo',
    name: 'distributionBillInfo',
    meta: {
      permissionCode: 'cp-hotelDistributionBill',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/settlement-center/distribution-bill/bill-info/index.vue'),
  },
];

// 酒店端路由-订单
const orderHotelRouter = [
  {
    path: 'orderList',
    name: 'hotelOrderList',
    meta: {
      permissionCode: 'cp-orderManage',
      routerType: 3,
      h_authCode: '2',
      keepAlive: true,
      deepth: 1,
    },
    component: () => import('../views/hotel-management/order-list/order-list.vue'),
  },
  {
    path: 'roomOrderDetail',
    name: 'roomOrderDetail',
    meta: {
      permissionCode: 'cp-orderManage',
      routerType: 3,
      deepth: 2,
    },
    component: () => import('../views/hotel-management/order-list/room-order-detail.vue'),
  },
  {
    // 会员升级订单
    path: 'saleGoldenCard',
    name: 'saleGoldenCard',
    meta: {
      permissionCode: 'cp-hotel-memberUpOrder',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/sale-card/sale-golden-card.vue'),
  },
];

const smartUpgradeRoomRouter = [
  {
    // 智能升房工具箱
    path: 'smartUpgradeRoomTool',
    name: 'smartUpgradeRoomTool',
    meta: {
      permissionCode: 'cp-hotel-smartUpgradeRoom',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/smartupgrade-room/smartupgraderoomtool/index.vue'),
    // component: () => import('../views/hotel-management/settlement-center/direct-sell-bill-new/index.vue'),

  },
  {
    // 智能升房设置
    path: 'smartUpgradeRoomTool/setting',
    name: 'smartUpgradeRoomToolSetting',
    meta: {
      permissionCode: 'cp-hotel-smartUpgradeRoom',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/smartupgrade-room/setting/index.vue'),
    // component: () => import('../views/hotel-management/settlement-center/direct-sell-bill-new/index.vue'),

  },
]; 

export default [
  ...settlementHotelRouter,
  ...orderHotelRouter,
  ...smartUpgradeRoomRouter,
];
