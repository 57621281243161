function download(url, name) {
  // 发送http请求，将文件链接转换成文件流
  fileAjax(url, (xhr) => {
    downloadFile(xhr.response, name);
  }, {
    responseType: 'blob',
  });
}

function fileAjax(url, callback, options) {
  const xhr = new XMLHttpRequest();
  xhr.open('get', url, true);
  if (options.responseType) {
    xhr.responseType = options.responseType;
  }
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      callback(xhr);
    }
  };
  xhr.send();
}

function downloadFile(content, filename) {
  let tempFileName;
  if (filename.indexOf('?') !== -1) {
    const arr = filename.split('?');
    tempFileName = arr[0];
  } else {
    tempFileName = filename;
  }

  window.URL = window.URL || window.webkitURL;
  const a = document.createElement('a');
  const blob = new Blob([content]);
  // 通过二进制文件创建url
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = tempFileName;
  a.click();
  // 销毁创建的url
  window.URL.revokeObjectURL(url);
}

export default download;
