<template>
  <div>
    <el-button :type="type"
               :icon="icon"
               :native-type="nativeType"
               :size="size"
               :loading="loading"
               :disabled="permissionDisable || disabled"
               :plain="plain"
               :autofocus="autofocus"
               :round="round"
               :circle="circle"
               @click="handleClick"
               v-if="permissionType === 'button'">
      {{ title }}
    </el-button>
    <el-switch v-else-if="permissionType === 'switch'"
               v-model="switchValue"
               :active-text="activeText"
               @change="statusChange"
               :inactive-text="inactiveText"
               :disabled="permissionDisable || disabled" />
    <div v-else-if="permissionType === 'empty'" @click="handleClick">{{ title }}</div>
  </div>
</template>

<script type="text/ecmascript-6">

export default {
  name: 'PermissionDisButton',
  props: {
    permissionCode: {
      type: String,
      default: '',
    },
    permissionType: {
      type: String,
      default: 'button',
    },
    title: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'primary',
    },
    size: String,
    icon: {
      type: String,
      default: '',
    },
    nativeType: {
      type: String,
      default: 'button',
    },
    loading: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    plain: Boolean,
    autofocus: Boolean,
    round: Boolean,
    circle: Boolean,

    // switch属性
    value: {
      type: [Boolean, String, Number],
      default: false,
    },
    width: {
      type: Number,
      default: 40,
    },
    activeIconClass: {
      type: String,
      default: '',
    },
    inactiveIconClass: {
      type: String,
      default: '',
    },
    activeText: String,
    inactiveText: String,
    activeColor: {
      type: String,
      default: '',
    },
    inactiveColor: {
      type: String,
      default: '',
    },
    activeValue: {
      type: [Boolean, String, Number],
      default: 1,
    },
    inactiveValue: {
      type: [Boolean, String, Number],
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    validateEvent: {
      type: Boolean,
      default: true,
    },
    id: String,
  },
  data() {
    return {
      switchValue: '',
      permissionDisable: true,
      tabList: [],
      isShow: true,
    };
  },
  methods: {
    handleClick(event) {
      if (this.permissionDisable) {
        this.$httpUtilSo.showToast('text', '没有操作权限');
      } else {
        this.$emit('handleClick');
      }
    },
    getButtonIsShow() {
      const perCodeList = localStorage.getItem('uiPermissionCodes')
        .split(',');
      this.permissionDisable = !(perCodeList.indexOf(this.permissionCode) >= 0 || this.permissionCode === 'all');
    },
    statusChange() {
      this.switchValue = !this.switchValue;
      this.$emit('statusChange');
    },
  },
  watch: {
    permissionDisable(value, oldValue) {
      if (value !== oldValue) {
        this.permissionDisable = value;
        this.getButtonIsShow();
      }
    },
    value(value, oldValue) {
      if (value !== oldValue) {
        this.value = value;
        this.getButtonIsShow();
      }
    },
  },
  created() {
    this.getButtonIsShow();
    if (this.permissionType === 'switch') {
      this.switchValue = this.value === this.activeValue;
    }
  },

  watch: {
    value(value, oldValue) {
      this.switchValue = value === this.activeValue;
    },
  },
  filters: {},
  components: {},
};
</script>

<style scoped>

</style>
